import axios from 'axios';

class ProductService {
  async list(params) {
    const response = await axios
      .get('api/products/', { params: params });
    return response.data;
  }

  async get(productId) {
    const response = await axios
      .get('api/products/' + productId + '/');
    return response.data;
  }

  async similar(productId) {
    const response = await axios
      .get('api/products/' + productId + "/similar/");
    return response.data;
  }

  async emailInfo(productId, name, email, jobNumber, dAndGJob) {
    const response = await axios
      .post('api/products/' + productId + "/email_info/", {
        name: name,
        email: email,
        job_number: jobNumber,
        d_and_g_job: dAndGJob,
      });
    return response.data;
  }

  async emailComparison(productIds, name, email, jobNumber, dAndGJob) {
    const response = await axios
      .post('api/products/email_comparison/', {
        product_ids: productIds,
        name: name,
        email: email,
        job_number: jobNumber,
        d_and_g_job: dAndGJob,
      });
    return response.data;
  }

  async valuesFor(field, category, brands) {
    const response = await axios
      .get('api/products/values_for/', { params: { field: field, category: category, brands: brands } });
    return response.data;
  }

  async searchEvent(keyword) {
    const response = await axios
      .post('api/products/search_event/', { search: keyword });
    return response.data;
  }

  async compareEvent(productId) {
    const response = await axios
      .post('api/products/' + productId + "/compare_event/", {});
    return response.data;
  }

  async minMaxPriceFor(category, brands) {
    const response = await axios
      .get('api/products/min_max_price_for/', { params: { category: category, brands: brands } });
    return response.data;
  }

  async getSuccessor(productId) {
      const response = await axios
      .get('api/products/' + productId + '/latest_successor/')
      return response.data;
  }

  async emailContactCentre(formData) {
    const response = await axios
      .post('/api/contact_center_emails/', formData , {
                headers: {
                    "Content-Type": "multipart/form-data"
                }});
    return response.data;
  }
}

export default new ProductService();